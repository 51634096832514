<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { claims } from "@/config/api/claims";
import ClaimDetails from "@/views/pages/claims/claim-details";
document.body.setAttribute("data-sidebar", "dark");

export default {
  page: {
    title: "Claims Table",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, ClaimDetails },
  data() {
    return {
      title: "Claims",
      breadcrumbs: [
        {
          text: "Claims",
          active: true,
        },
        {
          text: "",
        },
      ],
      claimData: [],
      selectedClaim: null,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      activeTab: null,
      filter: null,
      filterOn: [],
      sortBy: "createdAt",
      sortDesc: true,
      fields: [
        { key: "projectClaim", sortable: true },
        { key: "type", sortable: true },
        { key: "createdAt", sortable: true },
        { key: "updatedAt", sortable: true },
        { key: "approved_at", sortable: true, show: true },
        { key: "status", sortable: true, show: true },
        { key: "actions", sortable: false },
        { key: "show_details", sortable: false },
      ],
    };
  },
  computed: {
    rows() {
      if (this.claimData.docs) return this.claimData.docs.length;
      return this.claimData.length;
    },
    computedFields() {
      return this.fields;
    },
    deletedClaim() {
      return this.claimData.docs.filter((email) => email.approved_at === null);
    },
    activeClaim() {
      return this.claimData.docs.filter((email) => email.approved_at !== null);
    },
  },
  beforeMount() {
    this.loadData();
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.claimData.totalDocs;
  },
  methods: {
    reloadDataLimit() {
      this.loadData(1, this.perPage);
    },
    reloadData(page) {
      this.loadData(page, this.perPage);
    },
    toggleDetails(val) {
      val.toggleDetails();
      this.currentClaim(val);
    },
    tabChanged(currentTabs) {
      this.currentTabs = currentTabs;
      this.loadData();
    },
    async loadData(page = 1, limit = 10) {
      this.isBusy = true;
      const api = claims.get;
      api.params = {
        page: page,
        limit: limit,

      };
      switch (this.currentTabs) {
        case 1:
          api.params.type = "edit";
          break;
        case 2:
          api.params.type = "member";
          break;

        case 3:
          api.params.type = "company";
          break;
      }
      this.currentPage = page;
      await this.generateAPI(api)
        .then((res) => {
          this.claimData = res.data.claims;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    editClaim(data) {
      this.selectedClaim = data;
      let api = claims.apply;
      api.data = { id: data._id };
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Claim is approved", {
            title: "Approve",
            variant: "success",
            solid: true,
          });
          this.loadData();
        })
        .catch(() => {
          this.$bvToast.toast("Claim is not approved", {
            title: "Approve",
            variant: "danger",
            solid: true,
          });
        });
    },
    deleteClaim(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to decline this claim.`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            console.log(data);
            //TODO declide claim
            const api = claims.decline;
            api.data = { id: data._id };
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Claim declined successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.loadData();
              })
              .catch((err) => {
                this.$bvToast.toast("Claim is not declined", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    currentClaim(data) {
      return (this.selectedClaim = data);
    },
    addClaim() {
      this.selectedClaim = null;
      this.showActionModal();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom" active-nav-item-class="font-weight-bold"
              active-tab-class="font-weight-bold" content-class="mt-3" v-on:input="tabChanged">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Claims</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions" @input="reloadDataLimit">
                        </b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table :items="claimData.docs" :fields="fields" responsive="sm" :busy="isBusy"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader color="#505d69" class="loading-icon" :loading="true"></pulse-loader>
                      </div>
                    </template>
                    <template #cell(show_details)="row">
                      <b-button pill variant="primary" size="sm" @click="toggleDetails(row)" class="mr-2">
                        {{ row.detailsShowing ? "Hide" : "Show" }} Details
                      </b-button>
                    </template>

                    <template #row-details="row">
                      <b-card>
                        <ClaimDetails :currentClaim="row.item" :claimData="claimData" />
                      </b-card>
                    </template>
                    <template #cell(projectClaim)="row">
                      <div v-if="row.item.project">
                        {{ row.item.project.name_en }}
                      </div>
                    </template>
                    <template #cell(actions)="row">
                      <button :disabled="
                        row.item.approved_by || row.item.declined_by
                          ? true
                          : false
                      " class="action-btn" @click="deleteClaim(row.item)">
                        <i class="ri-delete-bin-line"></i>
                      </button>
                      <button :disabled="
                        row.item.approved_by || row.item.declined_by
                          ? true
                          : false
                      " class="action-btn" @click="editClaim(row.item)">
                        <i class="ri-check-fill"></i>
                      </button>
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("l") }}
                    </template>
                    <template #cell(updatedAt)="row">
                      {{ moment(row.item.updatedAt).format("l") }}
                    </template>
                    <template #cell(approved_at)="row">
                      {{
                      moment(row.item.approved_at).format("l") ===
                      "Invalid date"
                      ? "N/A"
                      : moment(row.item.approved_at).format("l")
                      }}
                    </template>
                    <template #cell(status)="row">
                      <div>
                        {{
                        row.item.approved_by
                        ? "Approved"
                        : row.item.declined_by
                        ? "Declined"
                        : "Pending"
                        }}
                      </div>
                    </template>
                    <template #cell(deletedAt)="row">
                      {{
                      row.item.deletedAt !== null
                      ? moment(row.item.deletedAt).format("l")
                      : ""
                      }}
                    </template>
                    <template #cell(deletedBy)="row">
                      {{
                      row.item.deletedBy !== null
                      ? row.item.deletedBy.name
                      : ""
                      }}
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="claimData.totalDocs" :per-page="perPage"
                          @change="reloadData"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Edit Claims</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions" @input="reloadDataLimit">
                        </b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table :items="claimData.docs" :fields="fields" responsive="sm" :busy="isBusy"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader color="#505d69" class="loading-icon" :loading="true"></pulse-loader>
                      </div>
                    </template>
                    <template #cell(show_details)="row">
                      <b-button pill variant="primary" size="sm" @click="toggleDetails(row)" class="mr-2">
                        {{ row.detailsShowing ? "Hide" : "Show" }} Details
                      </b-button>
                    </template>

                    <template #row-details="row">
                      <b-card>
                        <ClaimDetails :currentClaim="row.item" :claimData="claimData" />
                      </b-card>
                    </template>
                    <template #cell(projectClaim)="row">
                      <div v-if="row.item.project">
                        {{ row.item.project.name_en }}
                      </div>
                    </template>
                    <template #cell(actions)="row">
                      <button :disabled="
                        row.item.approved_by || row.item.declined_by
                          ? true
                          : false
                      " class="action-btn" @click="deleteClaim(row.item)">
                        <i class="ri-delete-bin-line"></i>
                      </button>
                      <button :disabled="
                        row.item.approved_by || row.item.declined_by
                          ? true
                          : false
                      " class="action-btn" @click="editClaim(row.item)">
                        <i class="ri-check-fill"></i>
                      </button>
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("l") }}
                    </template>
                    <template #cell(updatedAt)="row">
                      {{ moment(row.item.updatedAt).format("l") }}
                    </template>
                    <template #cell(approved_at)="row">
                      {{
                      moment(row.item.approved_at).format("l") ===
                      "Invalid date"
                      ? "N/A"
                      : moment(row.item.approved_at).format("l")
                      }}
                    </template>
                    <template #cell(status)="row">
                      <div>
                        {{
                        row.item.approved_by
                        ? "Approved"
                        : row.item.declined_by
                        ? "Declined"
                        : "Pending"
                        }}
                      </div>
                    </template>
                    <template #cell(deletedAt)="row">
                      {{
                      row.item.deletedAt !== null
                      ? moment(row.item.deletedAt).format("l")
                      : ""
                      }}
                    </template>
                    <template #cell(deletedBy)="row">
                      {{
                      row.item.deletedBy !== null
                      ? row.item.deletedBy.name
                      : ""
                      }}
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="claimData.totalDocs" :per-page="perPage"
                          @change="reloadData"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Member Claims</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions" @input="reloadDataLimit">
                        </b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table :items="claimData.docs" :fields="fields" responsive="sm" :busy="isBusy"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader color="#505d69" class="loading-icon" :loading="true"></pulse-loader>
                      </div>
                    </template>
                    <template #cell(show_details)="row">
                      <b-button pill variant="primary" size="sm" @click="toggleDetails(row)" class="mr-2">
                        {{ row.detailsShowing ? "Hide" : "Show" }} Details
                      </b-button>
                    </template>

                    <template #row-details="row">
                      <b-card>
                        <ClaimDetails :currentClaim="row.item" :claimData="claimData" />
                      </b-card>
                    </template>
                    <template #cell(projectClaim)="row">
                      <div v-if="row.item.project">
                        {{ row.item.project.name_en }}
                      </div>
                    </template>
                    <template #cell(actions)="row">
                      <button :disabled="
                        row.item.approved_by || row.item.declined_by
                          ? true
                          : false
                      " class="action-btn" @click="deleteClaim(row.item)">
                        <i class="ri-delete-bin-line"></i>
                      </button>
                      <button :disabled="
                        row.item.approved_by || row.item.declined_by
                          ? true
                          : false
                      " class="action-btn" @click="editClaim(row.item)">
                        <i class="ri-check-fill"></i>
                      </button>
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("l") }}
                    </template>
                    <template #cell(updatedAt)="row">
                      {{ moment(row.item.updatedAt).format("l") }}
                    </template>
                    <template #cell(approved_at)="row">
                      {{
                      moment(row.item.approved_at).format("l") ===
                      "Invalid date"
                      ? "N/A"
                      : moment(row.item.approved_at).format("l")
                      }}
                    </template>
                    <template #cell(status)="row">
                      <div>
                        {{
                        row.item.approved_by
                        ? "Approved"
                        : row.item.declined_by
                        ? "Declined"
                        : "Pending"
                        }}
                      </div>
                    </template>
                    <template #cell(deletedAt)="row">
                      {{
                      row.item.deletedAt !== null
                      ? moment(row.item.deletedAt).format("l")
                      : ""
                      }}
                    </template>
                    <template #cell(deletedBy)="row">
                      {{
                      row.item.deletedBy !== null
                      ? row.item.deletedBy.name
                      : ""
                      }}
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="claimData.totalDocs" :per-page="perPage"
                          @change="reloadData"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Company Claims</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions" @input="reloadDataLimit">
                        </b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                          class="form-control form-control-sm ml-2"></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table :items="claimData.docs" :fields="fields" responsive="sm" :busy="isBusy"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader color="#505d69" class="loading-icon" :loading="true"></pulse-loader>
                      </div>
                    </template>
                    <template #cell(show_details)="row">
                      <b-button pill variant="primary" size="sm" @click="toggleDetails(row)" class="mr-2">
                        {{ row.detailsShowing ? "Hide" : "Show" }} Details
                      </b-button>
                    </template>

                    <template #row-details="row">
                      <b-card>
                        <ClaimDetails :currentClaim="row.item" :claimData="claimData" />
                      </b-card>
                    </template>
                    <template #cell(projectClaim)="row">
                      <div v-if="row.item.project">
                        {{ row.item.project.name_en }}
                      </div>
                    </template>
                    <template #cell(actions)="row">
                      <button :disabled="
                        row.item.approved_by || row.item.declined_by
                          ? true
                          : false
                      " class="action-btn" @click="deleteClaim(row.item)">
                        <i class="ri-delete-bin-line"></i>
                      </button>
                      <button :disabled="
                        row.item.approved_by || row.item.declined_by
                          ? true
                          : false
                      " class="action-btn" @click="editClaim(row.item)">
                        <i class="ri-check-fill"></i>
                      </button>
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.item.createdAt).format("l") }}
                    </template>
                    <template #cell(updatedAt)="row">
                      {{ moment(row.item.updatedAt).format("l") }}
                    </template>
                    <template #cell(approved_at)="row">
                      {{
                      moment(row.item.approved_at).format("l") ===
                      "Invalid date"
                      ? "N/A"
                      : moment(row.item.approved_at).format("l")
                      }}
                    </template>
                    <template #cell(status)="row">
                      <div>
                        {{
                        row.item.approved_by
                        ? "Approved"
                        : row.item.declined_by
                        ? "Declined"
                        : "Pending"
                        }}
                      </div>
                    </template>
                    <template #cell(deletedAt)="row">
                      {{
                      row.item.deletedAt !== null
                      ? moment(row.item.deletedAt).format("l")
                      : ""
                      }}
                    </template>
                    <template #cell(deletedBy)="row">
                      {{
                      row.item.deletedBy !== null
                      ? row.item.deletedBy.name
                      : ""
                      }}
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="claimData.totalDocs" :per-page="perPage"
                          @change="reloadData"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- <ClaimModal
      :selectedClaim="selectedClaim"
      v-on:resetModal="selectedClaim = null"
      v-on:reloadData="loadData"
      v-on:closeModal="hideActionModal"
    /> -->
  </Layout>
</template>

<style>
#addClaims {
  float: right;
  margin-top: 10px;
}
</style>
