<script>
import appConfig from "@/app.config";
import { claims } from "@/config/api/claims";

export default {
  page: {
    title: "Claims",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {},
  props: ["currentClaim"],
  data() {
    return {
      cityData: [],
      title: "Claim Details",
      selectedCity: null,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      activeTab: null,
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      edits: {
        edits: {},
        projects: {},
      },
      editsLoading: false,
    };
  },
  created() {
    if (
      this.currentClaim.type === "edit" ||
      this.currentClaim.type === "company"
    ) {
      this.getClimesDetails(this.currentClaim._id);
    }
  },

  computed: {
    fields() {
      return [
        { key: "name", sortable: true },
        { key: "picture", sortable: true },
        { key: "email", sortable: true },
        { key: "role", sortable: true },
      ];
    },
    countries() {
      let countries = [];
      this.countriesData.forEach((element) => {
        countries.push({
          _id: element._id,
          name: element.name,
        });
      });
      return countries;
    },
    rows() {
      return this.cityData.length;
    },

    computedFields() {
      if (this.activeTab == 1) {
        return this.fields.filter((field) => !field.show);
      }
      return this.fields;
    },
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    prepareTitle(data) {
      return Object.keys(data);
    },
    getClimesDetails(id) {
      const api = claims.getUserProjectClaimV2;
      api.id = id;
      this.editsLoading = true;
      this.generateAPI(api)
        .then((res) => {
          this.compareClaims(res.data.claims);
        })
        .finally(() => {
          this.editsLoading = false;
        });
    },
    onRowClicked(data) {
      if (data.team_members && data.team_members.length && data.team_members[0].user && data.team_members[0].user._id)
        this.$router.push({
          path: "/user-info",
          query: { id: data.team_members[0].user._id },
        });
    },
    compareClaims(data) {
      let keys = Object.keys(data.edits);
      let edits = {};
      let projects = {};
      const remove = ["bcc", "national_id_back", "national_id_front"];
      const dates = ["start_date", "end_date"];

      remove.forEach((ele) => {
        keys = keys.filter((item) => item !== ele);
      });

      for (let i = 0; i < keys.length; i++) {
        const item = keys[i];

        if (data.edits[item] !== data.project[item]) {
          if (dates.includes(item)) {
            edits[item] = this.moment(data.edits[item]).format("LLL");
            projects[item] = this.moment(data.project[item]).format("LLL");
          } else {
            edits[item] = data.edits[item] || "";
            projects[item] = data.project[item] || "";
          }
        }
      }

      this.edits.edits = edits;
      this.edits.projects = projects;
    },
  },
};
</script>

<template>
  <div class="row">
    <!-- Table -->
    <div class="card-body pt-0">
      <!-- All Cities -->
      <b-table bordered striped v-if="currentClaim.type === 'member'" :items="currentClaim.team_members"
        :fields="fields" responsive="sm" :busy="isBusy" @row-clicked="onRowClicked(currentClaim)" show-empty>
        <template #table-busy>
          <div style="text-align: center" class="mt-5 mb-5">
            <pulse-loader color="#505d69" :loading="true"></pulse-loader>
          </div>
        </template>
        <template #cell(name)="row">
          {{
          currentClaim.type === "company"
          ? row.item.company.display_name
          : (row.item.user && row.item.user.name) || row.item.name
          }}
        </template>
        <template #cell(email)="row">
          {{ (row.item.user && row.item.user.email) || row.item.email }}
        </template>

        <template #cell(cover_image)="row">
          <a :href="row.item.company.cover_image" target="_blank">
            <img :src="row.item.company.cover_image" style="
                height: 50px;
                width: 50px;
                object-fit: cover;
                cursor: pointer;
              " /></a>
        </template>
        <template #cell(picture)="row">
          <div v-if="row.item.user">
            <img style="
                height: 50px;
                width: 50px;
                object-fit: cover;
                cursor: pointer;
              " :src="row.item.user.cover_image" alt="" srcset="" />
          </div>
          <div v-else>
            <b-avatar variant="info" :src="row.item.picture"></b-avatar>
          </div>
        </template>
      </b-table>
      <div v-if="currentClaim.type === 'edit'">
        <div style="text-align: center" class="mt-5 mb-5" v-if="editsLoading">
          <pulse-loader color="#505d69" :loading="true"></pulse-loader>
        </div>
        <div v-else class="row">
          <div class="col-md-5 card m-2 p-4">
            <h6>Data</h6>
            <ul>
              <li v-for="(val, key) in edits.projects" :key="key">
                <span class="text-capitalize">
                  {{ key.replace("_", " ") }}</span>
                :
                <em> {{ val || "N/A" }} </em>
              </li>
            </ul>
          </div>
          <div class="col-md-5 card m-2 p-4">
            <h6>Edits</h6>
            <ul>
              <li v-for="(val, key) in edits.edits" :key="key">
                <span class="text-capitalize">
                  {{ key.replace("_", " ") }}
                </span>
                :
                <em> {{ val.name ? val.name : val || "N/A" }} </em>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <b-table bordered striped v-if="currentClaim.type === 'company'" :items="currentClaim.companies" :fields="fields"
        responsive="sm" :busy="isBusy" show-empty>
        <template #table-busy>
          <div style="text-align: center" class="mt-5 mb-5">
            <pulse-loader color="#505d69" :loading="true"></pulse-loader>
          </div>
        </template>

        <template #cell(name)="row">
          <strong> {{ row.item.company.display_name }}</strong> <br />
          Legal name: <em>{{ row.item.company.legal_name }}</em>
        </template>

        <template #cell(cover_image)="row">
          <a :href="row.item.company.cover_image" target="_blank">
            <img :src="row.item.company.cover_image" style="
                height: 50px;
                width: 50px;
                object-fit: cover;
                cursor: pointer;
              " /></a>
        </template>
        <template #cell(picture)="row">
          <img :src="row.item.company.cover_image" style="
              height: 50px;
              width: 50px;
              object-fit: cover;
              cursor: pointer;
            " />
        </template>
      </b-table>
    </div>
  </div>
</template>

<style scoped>
#addCities {
  float: right;
  margin-top: 10px;
}

.row {
  background-color: #f1f5f7;
}
</style>
